<!--todo: verfiy when we integrate this-->
<template>
  <div>
    <core-modal-header>
      <template v-if="deleteAllPermanently">Empty Delete Bin?</template>
      <template v-else>Delete Files Permanently?</template>
    </core-modal-header>

    <core-modal-body>
      <u-alert
        color="red"
        :icon="COMMON_ICONS.alert"
        title="This action is final and cannot be undone."
        :description="description"
      />
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" size="xl" @click="close">Cancel</u-button>
      <u-button color="red" size="xl" @click="confirm" :loading="isSaving">
        <template v-if="deleteAllPermanently">Empty Delete Bin</template>
        <template v-else>Delete Permanently</template>
      </u-button>
    </core-modal-footer>
  </div>
</template>

<script setup>
  const emit = defineEmits(['success']);

  const props = defineProps({
    files: {
      type: Array,
      default: () => []
    },
    deleteAllPermanently: Boolean,
    modal: Object
  });

  const isSaving = ref(false);
  const description = props.deleteAllPermanently
    ? 'All Delete Bin files will be permanently deleted from your FOREVER Account and cannot be recovered.'
    : 'The selected Delete Bin files will be permanently deleted from your FOREVER Account and cannot be recovered.';

  function close() {
    props.modal.close();
  }

  async function confirm() {
    try {
      isSaving.value = true;
      let purgeSuccessToast;

      if (props.deleteAllPermanently) {
        await useFileApi().purgeAllDeletedFiles();
        useFilesStore().clearFiles();
        purgeSuccessToast = 'Your Delete Bin was emptied.';
      } else {
        await useFilesStore().purgeFiles({ids: props.files.map(f => f.id)})
        purgeSuccessToast = 'The selected file(s) were deleted permanently.';
      }
      useSessionStore().getDeleteBinSummary();

      useSuccessToast().add({
        id: 'file-purge-success',
        description: purgeSuccessToast
      });

      emit('success');

    } catch (e) {
      useErrorToast().add();
      close();
    } finally {
      isSaving.value = false;
    }
  }
</script>
