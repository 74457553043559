<template>
  <div>
    <core-modal-header>
      <template v-if="isMulti">Delete <span class="font-semibold">{{count}} Selected</span> Files?</template>
      <template v-else>Delete File <span class="font-semibold">{{fileName}}</span>?</template>
    </core-modal-header>

    <core-modal-body>
      <u-alert :icon="COMMON_ICONS.alert" color="red" :title="isMulti ? `The selected files will be:` : `The file will be:`">
        <template #description>
          <ul class="list-disc pl-4">
            <li>Removed from your Gallery, Tags, Albums, People, and Projects</li>
            <li>Stored in the Delete Bin for 60 days</li>
            <li>Permanently disconnected from all organization</li>
            <li>Inaccessible through any associated {{isMulti ? 'share links and/or QR codes' : 'share link and/or QR code'}}</li>
          </ul>
        </template>
      </u-alert>
    </core-modal-body>

    <core-modal-footer>
      <u-button size="xl" color="charcoal" variant="soft" @click="close">Cancel</u-button>
      <u-button size="xl" @click="confirm" :loading="isSaving" color="red">Delete File{{files.length > 1 ? 's' : null}}</u-button>
    </core-modal-footer>
  </div>
</template>

<script setup>
  import {makeFilesStore} from '~/stores/files.js';

  const emit = defineEmits(['success']);

  const props = defineProps({
    files: {
      type: Array,
      default: () => []
    },
    filesStoreId: String,
    modal: Object
  });

  const isSaving = ref(false);
  const filesStore = props.filesStoreId
      ? makeFilesStore(props.filesStoreId)()
      : useFilesStore();

  const count = computed(() => props.files.length);
  const isMulti = computed(() => count.value > 1,);
  const fileName = computed(() => count.value && html2text(props.files[0].name));

  function close() {
    props.modal.close();
  }

  async function confirm() {
    if (isSaving.value) return;

    try {
      isSaving.value = true;

      await filesStore.removeFilesFromLibrary({ids: props.files.map(f => f.id)});

      useSuccessToast().add({
        id: 'file-delete-success',
        description: isMulti.value
          ? `The ${count.value} selected files were moved to your Delete Bin.`
          : `The file ${fileName.value} was moved to your Delete Bin.`
      });

      emit('success');
    } catch (e) {
      useErrorToast().add();
      close();
    }
  }
</script>
